import { LogoPropsType } from './logoTypes';

export default function ObserveLogo({ monochrome, className }: LogoPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6ZM6 4.5C5.17157 4.5 4.5 5.17157 4.5 6V18C4.5 18.8284 5.17157 19.5 6 19.5H18C18.8284 19.5 19.5 18.8284 19.5 18V6C19.5 5.17157 18.8284 4.5 18 4.5H6ZM16.5 6.75C16.9142 6.75 17.25 7.08579 17.25 7.5V16.5C17.25 16.9142 16.9142 17.25 16.5 17.25C16.0858 17.25 15.75 16.9142 15.75 16.5V7.5C15.75 7.08579 16.0858 6.75 16.5 6.75ZM13.5 9C13.9142 9 14.25 9.33579 14.25 9.75V16.5C14.25 16.9142 13.9142 17.25 13.5 17.25C13.0858 17.25 12.75 16.9142 12.75 16.5V9.75C12.75 9.33579 13.0858 9 13.5 9ZM10.5 11.25C10.9142 11.25 11.25 11.5858 11.25 12V16.5C11.25 16.9142 10.9142 17.25 10.5 17.25C10.0858 17.25 9.75 16.9142 9.75 16.5V12C9.75 11.5858 10.0858 11.25 10.5 11.25ZM7.5 13.5C7.91421 13.5 8.25 13.8358 8.25 14.25V16.5C8.25 16.9142 7.91421 17.25 7.5 17.25C7.08579 17.25 6.75 16.9142 6.75 16.5V14.25C6.75 13.8358 7.08579 13.5 7.5 13.5Z"
        fill={monochrome ? 'currentColor' : 'url(#paint0_linear_11051_11823)'}
      />
      <defs>
        <linearGradient id="paint0_linear_11051_11823" x1="3" y1="3" x2="21" y2="21" gradientUnits="userSpaceOnUse">
          <stop stopColor="#209BC4" />
          <stop offset="1" stopColor="#4DC9C0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
