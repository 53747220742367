import { LogoPropsType } from './logoTypes';

export default function TroubleshootLogo({ monochrome, className }: LogoPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7514 5.00778C21.3333 4.4258 22.3198 4.6775 22.5598 5.45784C23.171 7.44503 22.6913 9.69886 21.1164 11.2737C19.8358 12.5543 18.105 13.1112 16.4355 12.9456L8.26697 21.1141C6.81933 22.5618 4.46493 22.5502 3.03194 21.0795C1.61248 19.6228 1.67943 17.2999 3.09694 15.8824L11.2346 7.74468C11.069 6.07516 11.6259 4.34436 12.9065 3.06382C14.4813 1.48894 16.7352 1.00925 18.7224 1.62044C19.5027 1.86044 19.7544 2.84686 19.1724 3.42884L16.8811 5.72013L17.2148 6.9654L18.4601 7.29907L20.7514 5.00778ZM21.2793 6.60113L19.3582 8.5223C19.0798 8.80064 18.6741 8.90934 18.2939 8.80746L16.6045 8.35478C16.2243 8.2529 15.9273 7.95592 15.8254 7.57571L15.3727 5.88629C15.2709 5.50607 15.3796 5.10038 15.6579 4.82204L17.5791 2.90088C16.2955 2.73106 14.9519 3.13971 13.9671 4.12448C13.013 5.07859 12.6 6.36823 12.7292 7.61554C12.7741 8.04868 12.6215 8.47911 12.3134 8.78724L4.1576 16.943C3.29967 17.801 3.28 19.1847 4.10627 20.0327C4.95505 20.9038 6.34907 20.9107 7.20631 20.0535L15.393 11.8668C15.7011 11.5587 16.1315 11.4061 16.5647 11.451C17.812 11.5802 19.1016 11.1672 20.0557 10.2131C21.0405 9.22832 21.4491 7.88467 21.2793 6.60113Z"
        fill={monochrome ? 'currentColor' : 'url(#paint1_linear_11051_11807)'}
      />
      <path
        d="M5.02529 19.1553C5.37963 19.5096 5.95414 19.5096 6.30849 19.1553C6.66284 18.8009 6.66284 18.2264 6.30849 17.8721C5.95414 17.5177 5.37963 17.5177 5.02529 17.8721C4.67094 18.2264 4.67094 18.8009 5.02529 19.1553Z"
        fill={monochrome ? 'currentColor' : 'url(#paint1_linear_11051_11807)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_11051_11807"
          x1="2"
          y1="1.36371"
          x2="22.8276"
          y2="22.1802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#209BC4" />
          <stop offset="1" stopColor="#4DC9C0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11051_11807"
          x1="2"
          y1="1.36371"
          x2="22.8276"
          y2="22.1802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#209BC4" />
          <stop offset="1" stopColor="#4DC9C0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
