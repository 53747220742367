import { LogoPropsType } from './logoTypes';

export default function AccelerateLogo({ monochrome, className }: LogoPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 4.5C7.96979 4.5 4.5 7.96979 4.5 12.25C4.5 16.5302 7.96979 20 12.25 20C16.5302 20 20 16.5302 20 12.25C20 11.8358 20.3358 11.5 20.75 11.5C21.1642 11.5 21.5 11.8358 21.5 12.25C21.5 17.3586 17.3586 21.5 12.25 21.5C7.14137 21.5 3 17.3586 3 12.25C3 7.14137 7.14137 3 12.25 3C13.1928 3 14.104 3.1413 14.9628 3.40439C15.3589 3.52572 15.5816 3.94513 15.4603 4.34118C15.3389 4.73722 14.9195 4.95993 14.5235 4.8386C13.8054 4.61864 13.0423 4.5 12.25 4.5ZM19.2457 5.25435C19.4913 5.49995 19.5361 5.88205 19.354 6.17784L14.6906 13.7535C13.72 15.3303 11.5315 15.5871 10.2222 14.2778C8.9129 12.9685 9.16969 10.78 10.7465 9.80936L18.3222 5.14599C18.6179 4.96391 19.0001 5.00874 19.2457 5.25435ZM16.4244 8.07564L11.5328 11.0867C10.7811 11.5495 10.6586 12.5929 11.2828 13.2171C11.9071 13.8414 12.9505 13.7189 13.4133 12.9672L16.4244 8.07564Z"
        fill={monochrome ? 'currentColor' : 'url(#paint0_linear_11051_11791)'}
      />
      <defs>
        <linearGradient id="paint0_linear_11051_11791" x1="3" y1="3" x2="21.5" y2="21.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#209BC4" />
          <stop offset="1" stopColor="#4DC9C0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
