export const LG_BREAKPOINT = 1919;
export const MD_BREAKPOINT = 1279;
export const SM_BREAKPOINT = 767;
export const GTM_ID = 'GTM-WRTQKGT';
export const GA_ID = 'G-7W7NC6YNPT';
export const VIDEO_ID = '3ikit0em21';
export const UTM_COOKIE = 'utm_campaign';
export const CLEARBIT_COOKIE = 'clearbit_params';
export const PLAYWRIGHT_IGNORE = 'playwright-ignore';
export const cookieConsentName = 'cookie-consent';

export const noop = () => {};
