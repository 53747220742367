'use client';

import { XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

type BannerProps = {
  handleCloseBanner: () => void;
  isBannerOpen: boolean;
};

function Banner({ handleCloseBanner, isBannerOpen }: BannerProps) {
  return (
    <div
      className={clsx(
        'bg-custom-gradient overflow-hidden transition-all duration-500',
        isBannerOpen ? 'max-h-[--navbarBannerMobileHeight]' : 'max-h-0',
      )}
      data-testid="announcement-banner"
      data-banner-open={isBannerOpen}
    >
      <div className="max-w-section flex h-[--navbarBannerMobileHeight] items-center justify-center px-3 py-[0.625rem] text-center text-body-md text-white md:h-[--navbarBannerHeight]">
        <p className="w-full">
          Develocity is an InfoWorld 2024 award winner!{' '}
          <a href="/develocity/trial/" className="mega-menu-link underline">
            Try it out today.
          </a>
        </p>
        <button
          onClick={handleCloseBanner}
          className="cursor-pointer rounded-full p-1 transition-colors hover:bg-gradle-blue"
        >
          <XMarkIcon className="size-5 transition-transform md:size-4" />
        </button>
      </div>
    </div>
  );
}

export default Banner;
