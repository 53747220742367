import { DevelocityLogoColor, GradleBuildTool } from '@/components/Logos';
import {
  CubeTransparentIcon,
  CpuChipIcon,
  BookOpenIcon,
  ChartBarIcon,
  AcademicCapIcon,
  CheckBadgeIcon,
  NewspaperIcon,
  SwatchIcon,
} from '@heroicons/react/24/outline';
import { AccelerateLogo, TroubleshootLogo, ObserveLogo } from '@/components/Logos';
import producttour from './producttour.jpg';
import dpeu from './dpeu.jpg';
import { StaticImageData } from 'next/image';

export type SubMenuKeyType =
  | 'product'
  | 'solutions-build-systems'
  | 'integrations'
  | 'resources'
  | 'oss-projects'
  | 'learning-center'
  | 'about-us'
  | 'brand';

export type SubMenuItemType = {
  icon?: typeof CubeTransparentIcon;
  buttonContent: string;
  menuItemKey?: SubMenuKeyType;
  logo?: React.ReactElement;
  link?: { href: string; external: boolean };
};

type NavigationBarItemsType = {
  leftNavSection: {
    title: string;
    subMenuItems: SubMenuItemType[];
  };
  rightNavSectionTitle?: string;
  href?: string;
}[];

export const navigationBarItems: NavigationBarItemsType = [
  {
    leftNavSection: {
      title: 'Products',
      subMenuItems: [
        {
          buttonContent: 'Develocity',
          menuItemKey: 'product',
          logo: <DevelocityLogoColor className="h-4 text-white" />,
          link: { href: '/develocity/', external: false },
        },
        {
          buttonContent: 'Build Tool',
          logo: <GradleBuildTool className="h-9 w-36" />,
          link: { href: 'https://gradle.org/', external: true },
        },
      ],
    },

    rightNavSectionTitle: 'Features',
  },
  {
    leftNavSection: {
      title: 'Solutions',
      subMenuItems: [
        {
          icon: CubeTransparentIcon,
          buttonContent: 'Build Systems',
          menuItemKey: 'solutions-build-systems',
        },
        {
          icon: CpuChipIcon,
          buttonContent: 'Integrations',
          menuItemKey: 'integrations',
        },
      ],
    },
  },
  {
    leftNavSection: {
      title: 'Resources',
      subMenuItems: [
        {
          icon: AcademicCapIcon,
          buttonContent: 'Learning Center',
          menuItemKey: 'learning-center',
        },
        {
          icon: BookOpenIcon,
          buttonContent: 'Resources',
          menuItemKey: 'resources',
        },
        {
          icon: ChartBarIcon,
          buttonContent: 'OSS Projects',
          menuItemKey: 'oss-projects',
        },
      ],
    },
  },
  {
    leftNavSection: { title: 'Pricing', subMenuItems: [] },
    href: '/pricing',
  },
  {
    leftNavSection: { title: 'Customers', subMenuItems: [] },
    href: '/customers',
  },
  {
    leftNavSection: {
      title: 'Company',
      subMenuItems: [
        {
          icon: CheckBadgeIcon,
          buttonContent: 'About Us',
          menuItemKey: 'about-us',
        },
        {
          icon: NewspaperIcon,
          buttonContent: 'Brand',
          menuItemKey: 'brand',
        },
        {
          icon: SwatchIcon,
          buttonContent: 'Press & Media',
          link: { href: '/press-media/', external: false },
        },
      ],
    },
  },
];

type SubMenuColumnType = 'links' | 'cards';

type SubMenuValueType = {
  type: SubMenuColumnType;
  title?: string;
  link?: {
    href: string;
    external?: boolean;
  };
  tab?: string;
  icon?: typeof CubeTransparentIcon | typeof AccelerateLogo;
  items: {
    title: string;
    text?: string;
    bgImg?: StaticImageData;
    link: {
      text?: string;
      href: string;
      external?: boolean;
    };
  }[];
}[];

type SubMenuItemsType = Record<SubMenuKeyType, SubMenuValueType>;

export const subMenuItems: SubMenuItemsType = {
  product: [
    {
      type: 'links',
      title: 'Accelerate',
      link: { href: '/develocity/accelerate/', external: false },
      icon: AccelerateLogo,
      items: [
        {
          title: 'Build Cache',
          link: { href: '/develocity/product/build-cache/', external: false },
        },
        {
          title: 'Predictive Test Selection',
          link: { href: '/develocity/product/predictive-test-selection/', external: false },
        },
        {
          title: 'Test Distribution',
          link: { href: '/develocity/product/test-distribution/', external: false },
        },
      ],
    },
    {
      type: 'links',
      title: 'Troubleshoot',
      link: { href: '/develocity/troubleshoot/', external: false },
      icon: TroubleshootLogo,
      items: [
        {
          title: 'Build Scan',
          link: { href: '/develocity/product/build-scan/', external: false },
        },
        {
          title: 'Flaky Test Detection',
          link: { href: '/develocity/product/flaky-test-detection/', external: false },
        },
        {
          title: 'Failure Analytics',
          link: { href: '/develocity/product/failure-analytics/', external: false },
        },
      ],
    },
    {
      type: 'links',
      title: 'Observe',
      link: { href: '/develocity/observe/', external: false },
      icon: ObserveLogo,
      items: [
        {
          title: 'Reporting & Visualization',
          link: { href: '/develocity/product/reporting-and-visualization/', external: false },
        },
        {
          title: 'Performance Insights',
          link: { href: '/develocity/product/performance-insights/', external: false },
        },
      ],
    },
    {
      type: 'links',
      title: 'Develocity',
      link: { href: '/develocity/', external: false },
      items: [
        {
          title: 'Overview',
          link: { href: '/develocity/', external: false },
        },
        {
          title: 'Security',
          link: { href: '/develocity/solutions/security/', external: false },
        },
      ],
    },
  ],
  'solutions-build-systems': [
    {
      type: 'links',
      items: [
        {
          title: 'Gradle Build Tool',
          link: { href: '/gradle-enterprise-solutions/gradle-build-tool/', external: false },
        },
        {
          title: 'Android Studio',
          link: { href: '/gradle-enterprise-solutions/android-build-system/', external: false },
        },
        {
          title: 'Apache Maven',
          link: { href: '/gradle-enterprise-solutions/maven-build-tool/', external: false },
        },
        {
          title: 'Bazel',
          link: { href: '/gradle-enterprise-solutions/bazel-build-system/', external: false },
        },
        {
          title: 'sbt',
          link: { href: '/gradle-enterprise-solutions/sbt-build-system/', external: false },
        },
      ],
    },
  ],
  integrations: [
    {
      type: 'links',
      items: [
        {
          title: 'CI Systems',
          link: { href: '/develocity/integrations#ci-systems', external: false },
        },
        {
          title: 'Programming Languages',
          link: { href: '/develocity/integrations#programming-languages', external: false },
        },
        {
          title: 'IDEs',
          link: { href: '/develocity/integrations#ides', external: false },
        },
        {
          title: 'Test Frameworks',
          link: { href: '/develocity/integrations#test-frameworks', external: false },
        },
        {
          title: 'Cloud Infrastructure',
          // TODO: Confirm where this should go?
          link: { href: '/develocity/integrations#cloud-infrastructure', external: false },
        },
      ],
    },
  ],
  resources: [
    {
      type: 'links',
      items: [
        {
          title: 'Develocity Documentation',
          link: { href: '/develocity/resources/', external: false },
        },
        {
          title: 'API Documentation',
          link: { href: 'https://docs.gradle.com/develocity/api-manual/', external: false },
        },
        {
          title: 'DPE Newsletter',
          link: { href: '/gradle-dpe-newsletters/', external: false },
        },
        {
          title: 'Blog',
          link: { href: '/blog/', external: false },
        },
        {
          title: 'Events & Webinars',
          link: { href: '/events/', external: false },
        },
        {
          title: 'YouTube',
          link: { href: 'https://www.youtube.com/channel/UCvClhveoEjokKIuBAsSjEwQ', external: true },
        },
      ],
    },
  ],
  'oss-projects': [
    {
      type: 'links',
      items: [
        {
          title: 'Apache Software Foundation',
          link: { href: 'https://ge.apache.org/scans?search.timeZoneId=America%2FNew_York', external: true },
        },
        {
          title: 'Spring',
          link: {
            href: 'https://ge.spring.io/scans/performance?performance.metric=avoidanceSavings&search.relativeStartTime=P28D&search.rootProjectNames=spring-boot-build&search.tags=ci&search.timeZoneId=Europe/Zurich',
            external: true,
          },
        },
        {
          title: 'JetBrains Kotlin',
          link: { href: 'https://ge.jetbrains.com/scans?search.timeZoneId=America%2FNew_York', external: true },
        },
        {
          title: 'JUnit',
          link: {
            href: 'https://ge.junit.org/scans/tests?search.relativeStartTime=P28D&search.tags=ci&search.timeZoneId=Europe/Zurich&tests.sortField=FLAKY&tests.unstableOnly=true',
            external: true,
          },
        },
        {
          title: 'Apollo Kotlin',
          link: { href: 'https://ge.apollographql.com/scans', external: false },
        },
        {
          title: 'See all',
          link: { href: '/customers/oss-projects/', external: false },
        },
      ],
    },
  ],
  'learning-center': [
    {
      type: 'cards',
      items: [
        {
          title: 'DPE University',
          text: 'Start your journey to Developer Productivity Engineering mastery.',
          bgImg: dpeu,
          link: { href: 'https://dpeuniversity.gradle.com/app/', external: false, text: 'Get started' },
        },
        {
          title: 'Product Tour',
          text: 'Explore interactive demos of Develocity features.',
          bgImg: producttour,
          link: { href: '/develocity/product-tour/home/', external: false, text: 'Learn more' },
        },
      ],
    },
  ],
  'about-us': [
    {
      type: 'links',
      items: [
        {
          title: 'Our Story',
          link: { href: '/our-story/', external: false },
        },
        {
          title: 'Who We Are',
          link: {
            href: '/who-we-are/',
            external: false,
          },
        },
        {
          title: 'Our Team',
          link: { href: '/our-team/', external: false },
        },
        {
          title: 'Careers',
          link: {
            href: '/careers/',
            external: false,
          },
        },
      ],
    },
  ],
  brand: [
    {
      type: 'links',
      items: [
        {
          title: 'Assets & Guidelines',
          link: { href: '/brand/', external: false },
        },
      ],
    },
  ],
};
